export function addMask(
  value: string,
  mask: string | null,
  keepMasked = true,
): string | null {
  if (!mask) {
    return null;
  }

  const tokens: {
    [key: string]: { pattern: RegExp; transform?: (v: string) => string };
  } = {
    "#": { pattern: /\d/ },
    S: { pattern: /[a-zA-Z]/ },
    A: { pattern: /[0-9a-zA-Z]/ },
    U: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
    L: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
  };

  let iMask = 0;
  let iValue = 0;
  let output = "";
  while (iMask < mask.length && iValue < value.length) {
    const cMask = mask[iMask];
    const masker = tokens[cMask];
    const cValue = value[iValue];
    if (masker) {
      if (masker.pattern.test(cValue)) {
        output += masker.transform ? masker.transform(cValue) : cValue;
        iMask++;
      }
      iValue++;
    } else {
      if (keepMasked) {
        output += cMask;
      }
      if (cValue === cMask) {
        iValue++;
      }
      iMask++;
    }
  }

  return output;
}

function stripDelimiters(value: string, delimiter: string): string {
  // single delimiter
  // if (delimiters.length === 0) {
  const delimiterRE = delimiter
    ? new RegExp(delimiter.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), "g")
    : "";

  return value.replace(delimiterRE, "");
  // }

  // multiple delimiters Добавить третим аргументов delimeters
  // delimiters.forEach(function (current) {
  //     current.split('').forEach(function (letter) {
  //         value = value.replace(owner.getDelimiterREByDelimiter(letter), '');
  //     });
  // });
  // return value;
}

function getPositionOffset(
  prevPos: number,
  oldValue: string,
  newValue: string,
  delimiter: string,
): number {
  const oldRawValue = stripDelimiters(oldValue.slice(0, prevPos), delimiter);
  const newRawValue = stripDelimiters(newValue.slice(0, prevPos), delimiter);
  const lengthOffset = oldRawValue.length - newRawValue.length;

  return lengthOffset !== 0 ? lengthOffset / Math.abs(lengthOffset) : 0;
}

export function getNextCursorPosition(
  prevPos: number,
  oldValue: string,
  newValue: string | null,
  delimiter: string,
): number {
  // If cursor was at the end of value, just place it back.
  // Because new value could contain additional chars.
  if (newValue === null) {
    return 0;
  }
  return oldValue.length === prevPos
    ? newValue.length
    : prevPos + getPositionOffset(prevPos, oldValue, newValue, delimiter);
}

export function setSelection(
  element: HTMLInputElement,
  position: number,
): void {
  if (element === document.activeElement) {
    if (element && element.value.length <= position) {
      return;
    }

    if (element.createTextRange) {
      const range = element.createTextRange();
      range.move("character", position);
      range.select();
    } else {
      try {
        element.setSelectionRange(position, position);
      } catch (e) {
        console.warn("The input element type does not support selection");
      }
    }
  }
}
